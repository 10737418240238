export async function useGuardedQuery(path: string, variables: any) {
    const requestHeaders: Record<string, string> = {};

    const route = useRoute();

    if (route.query?.token) {
        requestHeaders['X-Craft-Token'] = route.query?.token as string;
    }

    const response = await useFetch(
        '/api/queries' + path,
        {
            method: 'POST',
            body: {
                variables: {
                    ...variables,
                    uri: decodeURI(variables.uri) || null
                }
            },
            headers: requestHeaders
        }
    ) as {
        data: Ref<{
            data: any;
            error: any
        }>
        error: any;
        pending: Object
    };

    return response;
}
